<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link
          :to="'/profile/education/update/' + $route.params.id"
          type="button"
        >
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Eğitim Modülleri</h3>
        <div class="card-options">
          <router-link
            :to="
              '/profile/education/update/' +
              $route.params.id +
              '/moduller/video'
            "
            class="btn btn-primary"
            >Video Listesi</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary mb-2" @click="addModule">
              Yeni Modül
            </button>
          </div>
          <div class="col-12">
            <div
              class="panel-group1"
              v-for="(item, i) in modules"
              :key="i"
              :id="'accordion' + i"
            >
              <div class="panel panel-default mb-4 border p-0">
                <div class="panel-heading1">
                  <h4 class="panel-title1">
                    <a
                      class="accordion-toggle collapsed"
                      data-bs-toggle="collapse"
                      :data-parent="'#accordion' + i"
                      :href="'#ac' + i"
                      aria-expanded="false"
                      >{{ item.title }}</a
                    >
                  </h4>
                </div>
                <div
                  :id="'ac' + i"
                  class="panel-collapse collapse active"
                  role="tabpanel"
                  aria-expanded="false"
                >
                  <div class="panel-body bg-white">
                    <div class="input-group mb-3">
                      <input class="form-control" v-model="item.title" />
                      <button class="btn btn-primary" @click="addSubModule(i)">
                        <i class="fa fa-plus"></i>
                      </button>
                      <button class="btn btn-warning" @click="removeModule(i)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                    <!--İÇERİK-->
                    <div
                      class="panel-group1"
                      v-for="(a, t) in item.subModules"
                      :key="t"
                      :id="'sub_accordion' + t + '_' + i"
                    >
                      <div
                        class="panel panel-default mb-4 border p-0"
                        style="border: 1px solid #bb74946b !important"
                      >
                        <div class="panel-heading1">
                          <h4 class="panel-title1">
                            <a
                              class="accordion-toggle collapsed"
                              data-bs-toggle="collapse"
                              :data-parent="'#sub_accordion' + t + '_' + i"
                              :href="'#s_ac' + i + '_' + t"
                              aria-expanded="false"
                              >{{ a.title }}</a
                            >
                          </h4>
                        </div>
                        <div
                          :id="'s_ac' + i + '_' + t"
                          class="panel-collapse collapse active"
                          role="tabpanel"
                          aria-expanded="false"
                        >
                          <div class="panel-body bg-white">
                            <div class="input-group mb-3">
                              <input class="form-control" v-model="a.title" />
                              <button
                                class="btn btn-warning"
                                @click="removeSubModule(i, t)"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                            <div class="form-group">
                              <label class="form-label">Video Yolu</label>
                              <select
                                class="form-control"
                                v-model="a.education_video_id"
                              >
                                <option
                                  v-for="(item, i) in videos"
                                  :key="i"
                                  :value="item.id"
                                >
                                  {{ item.title }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label class="form-label">Açıklama</label>
                              <textarea
                                class="form-control"
                                v-model="a.content"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
          type="button"
          id="saveButton"
          class="btn btn-primary float-right"
          @click="save"
        >
          Modülü Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
  <style scoped>
.panel-title1 {
  background: #bb74942b;
}
.border {
  border: 1px solid #ffffff !important;
}
</style>
  <script>
export default {
  created() {
    document.title = "Eğitim Modülleri";
    /**Modülleri Getir*/
    let educationUpdate = { id: this.$route.params.id };
    this.$store
      .dispatch("educationModuleGet", educationUpdate)
      .then((value) => {
        this.modules = value.list;
        this.videos = value.videos;
      });
  },
  data() {
    return {
      modules: [],
      videos: [],
    };
  },
  methods: {
    addModule() {
      this.modules.push({ title: "Modül Adı", subModules: [] });
    },
    addSubModule(i) {
      this.modules[i].subModules.push({
        title: "Modül Adı",
        education_video_id: 0,
        content: "",
      });
    },
    removeModule(i) {
      this.modules.splice(i, 1);
    },
    removeSubModule(i, t) {
      this.modules[i].subModules.splice(t, 1);
    },
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Bilgiler kayıt ediliyor..";
      let educationUpdate = {
        modules: this.modules,
        id: this.$route.params.id,
      };
      this.$store
        .dispatch("educationModuleUpdate", educationUpdate)
        .then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
            if (value.message == "ERRxTNACPT") {
              this.$vToastify.warning(
                "Tarih aralığı ve eğitim süresi kabul edilen sınırın dışında",
                "Uyarı!"
              );
            }
            if (value.message == "ERRxUNACCPT") {
              this.$vToastify.warning(
                "Tarih aralıkları bugün ve bugünden küçük olamaz",
                "Uyarı!"
              );
            }
          } else {
            if (this.status == 1) {
              this.$vToastify.success(
                "Eğitim güncellendi ve onaya gönderildi",
                "Başarılı!"
              );
              this.$router.push("/profile/education");
            }
            this.$vToastify.success(
              "Eğitim başarılı bir şekilde güncellendi",
              "Başarılı!"
            );
          }
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML =
            "Değişiklikleri Kaydet";
        });
    },
  },
  components: {},
  mounted() {},
};
</script>